import React from "react";
import { Helmet } from "react-helmet";

import { Map } from "../components/Map/index";

import logo from "../assets/logo/logo-outer-shadow.png";

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Дентално Студио "ДИМАНОВИ"</title>
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <link
        href="https://fonts.googleapis.com/css?family=Rubik"
        rel="stylesheet"
      />
      <link rel="canonical" href="http://dimanovi.com" />
    </Helmet>
    <div>
      <header className="masthead flex flex--center">
        <div className="logo-item">
          <a href="/">
            <img className="logo" src={logo} alt="Дентално Студио ДИМАНОВИ" />
          </a>
        </div>
        <div className="phone-num-item">
          <ul className="doctors">
            <li className="doctor-item">
              <span className="doctor--name">д-р Деница Диманова</span>
              <span className="doctor--phone">0886 13 42 53</span>
            </li>
            <li className="doctor-item">
              <span className="doctor--name">д-р Деян Диманов</span>
              <span className="doctor--phone">0899 01 03 03</span>
            </li>
          </ul>
        </div>
      </header>
      <main className="main-content flex">
        <div className="contacts flex">
          <div className="contacts--item">
            <p>
              <strong>Контакти:</strong>
              <br />
              гр. Велико Търново
              <br />
              ул. "Димитър Буйнозов" 7
            </p>
          </div>
          <div className="contacts--item">
            <p>
              <strong>Работно време:</strong>
              <br />
              Понеделник - Петък:
              <br />
              от 9.00ч до 18:00ч.
              <br />
              {/* Събота и неделя - почивни дни */}
            </p>
          </div>
        </div>
        <div className="map">
          <div className="mapouter">
            <div class="map-responsive">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2914.0207028126956!2d25.61222461570956!3d43.08306009712725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a925e284a066b3%3A0xf5b757b4c9642278!2sul.+%22Dimitar+Buynozov%22+7%2C+5000+g.k.+Akatsia%2C+Veliko+Tarnovo!5e0!3m2!1sen!2sbg!4v1549722173635" width="100%" height="523" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </main>
    </div>
  </>
);
